.footer {
  background-color: #252f3d;
  color: #fff;
  font-size: 14px;
  padding: 56px 0;
  margin-top: 35px;

  @media screen and (max-width: 720px) {
    padding: 35px 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 720px) {
      flex-direction: column;
    }
  }

  &__column {
    width: 22%;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    h3 {
      margin: 0;
      margin-bottom: 15px;
      font-size: 20px;
    }

    @media screen and (max-width: 1000px) {
      width: 30%;
    }

    @media screen and (max-width: 720px) {
      width: 100%;
      margin-bottom: 28px;
    }
  }

  &__contact {
    p {
      margin-bottom: 21px;
    }

    ul {
      margin-bottom: 14px;
      li {
        line-height: 1.5;
      }
    }

    a {
      color: #fff;
      font-weight: bold;
    }
  }

  &__socials {
    ul {
      @media screen and (max-width: 720px) {
        display: flex;
        justify-content: space-between;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 720px) {
        margin: 0;
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 14px;
      }
    }

    a {
      color: #fff;
      font-size: 15px;
      margin-left: 7px;
    }

    svg {
      // height: 14px;
      // width: 14px;
      border: 2px solid #fff;
      padding: 7px;
      border-radius: 28px;
    }
  }

  &__about {
    p {
      line-height: 1.35;
      font-weight: 500;
    }

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  &__legal {
    text-align: right;

    @media screen and (max-width: 720px) {
      text-align: left;
      margin-bottom: 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media screen and (max-width: 720px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      @media screen and (max-width: 640px) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      li {
        line-height: 1.4;
        margin-bottom: 7px;

        @media screen and (max-width: 640px) {
          padding-right: 28px;
          margin-bottom: 14px;
        }

        a {
          color: #fff;
          opacity: 0.7;
          transition: opacity ease 0.2s;
          text-decoration: none;
          font-weight: 500;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &__logos {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-size: contain;
    margin-top: 21px;

    @media screen and (max-width: 720px) {
      flex-wrap: nowrap;
      margin-top: 35px;
    }

    @media screen and (max-width: 520px) {
      align-items: center;
      flex-wrap: wrap;
    }

    .logo {
      display: block;
      height: 35px;
      display: inline-flex;
      margin-right: 14px;
      justify-content: flex-end;
      width: 30%;

      &:last-child {
        margin-right: 0;
      }

      &--paypal {
        width: auto;
      }

      svg {
        height: 100%;
      }

      @media screen and (max-width: 720px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 520px) {
        // height: 21px;
        margin-bottom: 21px;
        height: 28px;
        width: 50%;
        margin-right: 0;
        margin-bottom: 14px;
      }
    }
  }
}
