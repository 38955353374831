.copyright {
  background-color: #131a22;
  padding: 28px 0;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;

  &__logo {
    margin-right: 14px;
  }
}
