body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  .main {
    height: 100%;
    flex-grow: 1;
  }
  * {
    box-sizing: border-box;
  }
}

.app-container {
  max-width: 1230px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.form-group {
  input {
    transition: box-shadow ease 0.21s, background-color ease 0.21s;
    border: 1px solid #eee;
    max-width: 350px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(#666ee8, 0.35);
      background-color: rgba(#666ee8, 0.07);
    }
  }
}

.get-shop {
  h2 {
    font-weight: normal;
    font-size: 28px;
    margin-top: 0;
  }

  &__form {
    display: flex;
    align-items: flex-end;

    .form-group {
      margin-right: 21px;
    }

    input {
      margin-bottom: 0;
    }
  }

  &__next-button {
    align-self: flex-end;
  }

  &__help {
    margin-top: 35px;

    img {
      display: block;
      height: 350px;
      width: auto;
      background-color: #eee;
      max-width: 100%;
    }
  }

  &__show {
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 14px;
  }
}

.payment-data {
  padding: 21px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
  line-height: 1.5;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    align-items: stretch;
  }

  &__desc {
    span {
      color: #666;
    }
  }

  &__total {
    @media screen and (max-width: 680px) {
      margin-top: 21px;
    }
  }

  &__total-row {
    display: flex;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 14px;
    justify-content: flex-end;
    color: #666;
    line-height: 1;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: bold;
      font-size: 17px;
      margin-left: 7px;
      color: #333;
    }
  }
  &__to-pay {
    color: #d24826 !important;
    font-size: 20px !important;
  }
}

/* stripe elements */
.error {
  color: #e4584c;
}

.stripe-request {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.payment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stripe-request div form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

button {
  align-self: center;
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

button[disabled] {
  opacity: 0.6;
}

input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
}

input::placeholder {
  color: #aab7c4;
}

.msg,
.result,
.error {
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 40px;
  max-width: 100%;
}

.msg {
  color: inherit;
}

.error {
  color: #e4584c;
}

.result {
  color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementFpx {
  padding: 0;
}

.price-span {
  width: 100px;
  text-align: end;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.7;
}

.payment-placeholder {
  margin-top: 30px;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  align-self: center;
  justify-self: center;
  max-width: 500px;
  width: 100%;
}
